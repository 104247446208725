export const ACCOUNT = 'ACCOUNT';
export const APPINSTALLED = 'APPINSTALLED';
export const CACHED_ACCOUNT = 'CACHED_ACCOUNT';
export const CANVA_AUTHORIZE_URL = 'CANVA_AUTHORIZE_URL';
export const CANVA_CONNECTED = 'CANVA_CONNECTED';
export const CANVA_DESIGN_UPLOAD_DETAILS = 'CANVA_DESIGN_UPLOAD_DETAILS';
export const CANVA_DESIGN_UPLOAD_STATUS = 'CANVA_DESIGN_UPLOAD_STATUS';
export const CANVA_EXPORT_JOB = 'CANVA_EXPORT_JOB';
export const CANVA_USER_PROFILE = 'CANVA_USER_PROFILE';
export const CANVA_USERS = 'CANVA_USERS';
