import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { I18nContext, I18nManager, useI18n, type TranslationDictionary } from '@shopify/react-i18n';
import { useEffect, useMemo, useState, type PropsWithChildren } from 'react';
import CustomLinkComponent from '../CustomLinkComponent';
import { useAccount } from '../hooks/useAccount';
import defaultTranslations from '../locales/en.json';

const LOCALES = import.meta.glob('../locales/*.json');
const POLARIS_LOCALES = import.meta.glob('../../node_modules/@shopify/polaris/locales/*.json');

const DEFAULT_LOCALE = 'en';

export type I18nProviderProps = PropsWithChildren & {
	localeParam?: string;
};

export default function I18nProvider({ localeParam, children }: I18nProviderProps) {
	const account = useAccount();
	const [polarisTranslations, setPolarisTranslations] = useState<TranslationDictionary>(translations);
	const [detectedLocale, setDetectedLocale] = useState<string | undefined>(localeParam);
	const [locale, setLocale] = useState(DEFAULT_LOCALE);

	useEffect(() => {
		if (!detectedLocale && account.primaryLocale) {
			setDetectedLocale(account.primaryLocale);
		}
	}, [detectedLocale, account.primaryLocale]);

	useEffect(() => {
		async function fetchPolarisTranslations() {
			if (!detectedLocale) {
				return;
			}
			const localeFile = `../../node_modules/@shopify/polaris/locales/${detectedLocale}.json`;
			if (typeof POLARIS_LOCALES[localeFile] === 'function') {
				try {
					const translations = await POLARIS_LOCALES[localeFile]();
					setPolarisTranslations(translations as TranslationDictionary);
					setLocale(detectedLocale);
				} catch (e) {
					setLocale(DEFAULT_LOCALE);
					console.error(e);
				}
			} else {
				if (detectedLocale.includes('-')) {
					setDetectedLocale(detectedLocale.substring(0, detectedLocale.indexOf('-')));
				} else {
					setLocale(DEFAULT_LOCALE);
				}
			}
		}

		fetchPolarisTranslations();
	}, [detectedLocale]);

	const i18nManager = useMemo(() => {
		return new I18nManager({
			locale,
			timezone: account.timezone,
			country: account.countryCode,
			currency: account.currency,
			fallbackLocale: DEFAULT_LOCALE
		});
	}, [account.countryCode, account.currency, account.timezone, locale]);

	return (
		<I18nContext.Provider value={i18nManager}>
			<AppProvider i18n={polarisTranslations} linkComponent={CustomLinkComponent}>
				<I18nContainer>{children}</I18nContainer>
			</AppProvider>
		</I18nContext.Provider>
	);
}

function I18nContainer({ children }: PropsWithChildren) {
	const [, ShareTranslations] = useI18n({
		id: 'Canva',
		fallback: {
			...defaultTranslations
		},
		async translations(locale) {
			try {
				const appTranslations = (await LOCALES[`../locales/${locale}.json`]()) as TranslationDictionary;
				return {
					...appTranslations
				};
			} catch (e) {
				console.error(e);
			}
			return undefined;
		}
	});

	return <ShareTranslations>{children}</ShareTranslations>;
}
